import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default/DefaultTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <iframe className="airtable-embed" src="https://airtable.com/embed/shrsTEKnPYxEEIZti?backgroundColor=cyan" frameBorder="0" onmousewheel="" width="100%" height="1850" style={{
      "marginTop": "3rem"
    }}></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      